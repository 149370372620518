/*
This is where you write custom SASS
*/

@media screen and (min-width: 992px) {
    ul#main-menu ul {
        > li {
            > a {
                &.current {
                    color: $primary !important;
                }
                &:hover {
                    color: rgba(#e9ecef,.85)!important
                }
            }
        }
    }
}

.nav-wrap {
    img.slide-flowers {
        width: 194px;
        height: 495px;
        position: absolute;
        z-index: 9;
        right: -15px;
        bottom: -10px;
        @media screen and (max-width: 991px) {
            & {
                bottom: -21px;
            }
        }
        @media screen and (max-width: 576px) {
            & {
                right: -6%;
                height: 80vw !important;
                width: auto;
            }
        }
    }
}

section.contact-section {
    h1, h2, .h1, .h2 {
        display: inline-block;
    }
}
h1, h2, .h1, .h2 {
    &:before {
        content: attr(data-first-letter);
        font-family: 'Pinyon Script', cursive;
        display: block;
        position: absolute;
        top: -15px;
        left: 15px;
        z-index: 0;
        font-size: 60px;
        color: #1c1916;
    }
    > span {
        position: relative;
        z-index: 1;
    }
}

.phone-dropdown-menu,
.address-dropdown-menu {
  transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
  display: block;
  opacity: 0;
  visibility: hidden;
  padding: 1rem * 0.5;
  border: none;
  background: transparent;
  margin-top: -1.5rem !important;
  max-width: 100% !important;
  min-width: 16rem !important;
  margin-right: -5px;
  box-shadow: none !important;

  .address-suite,
  .phone-number {
      font-family: $headings-font-family;
      font-size: $small-font-size;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 5%;
    bottom: 25%;
    left: 25%;
    right: 25%;
    transition: 0.3s ease;
    border-radius: $border-radius;
    background-color: #e4e1dd !important;
    box-shadow: $box-shadow-sm;
    opacity: 0;
  }

  &.show {
    opacity: 1;
    visibility: visible;

    &:before {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
    }

    ul {
      @for $i from 1 to 10 {
        a:nth-child(#{$i}) {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
          transition-delay: 0.2 + ($i * 0.025s);
        }
      }
    }
  }


  @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
    left: 50% !important;
    transform: translate3d(-50%, 0, 0) !important;
    top: 2rem !important;
    width: 90%;
    max-width: 300px !important;
  }

  ul {
    padding: 0;
    margin: 0;

    a {
      font-size: $font-size-base;
      line-height: 1.4em;
      transition: 0.3s ease;
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 1rem, 0);
      display: block;
      span {
        color: $gray-900 !important;
      }

      .row {
        padding: 1rem * 0.5 1rem * 0.25 1rem * 0.5 1rem;
        position: relative;

        .font-weight-bold {
          font-weight: 400 !important;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          top: 5%;
          bottom: 5%;
          left: 5%;
          right: 5%;
          transition: 0.3s ease;
          border-radius: $border-radius;
          background-color: transparent !important;
          opacity: 0;
        }
      }

      &:hover {
        span {
            color: darken($gray-900, 5%) !important;
        }
        .row {
          &:before {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 1;
          }
        }
      }
    }
  }
}


div.social {
    top: 50%; 
    a[href*="instagram"] {
        background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    }
    a[href*="facebook"] {
        background: #3B5998
    }
    a[href*="twitter"] {
        background: #1DA1F2;
    }
    a[href*="yelp"] {
        background: #AF0606;
    }
    a[href*="youtube"] {
        background: #FF0000;
    }
    a[href*="linkedin"] {
        background: #0077B5;
    }
    a[href*="google"] {
        background: #4285F4;
    }
}


#quickLinks {
    span.phone-number,
    span.address-suite {
        font-weight: 600 !important;
    }
    .phone-dropdown-menu a , 
    .address-dropdown-menu a {
        transition: .3s ease;
        border-radius: 3px;
        &:hover {
            background: #ebe7e3 !important;

        }
    }
}





.bg-nav {
    background-image: url('/prositesStyles/StyleLibrary/6309/images/nav-bg.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.59); 
}
.top-nav {
    margin: 0 auto;
    display: block;
}
.bg-contact {
    background: url('/prositesStyles/StyleLibrary/6309/images/contact-bg.jpg');
    background-position: top center;
    background-repeat: no-repeat;
}
header {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d0cbc5+0,e5e1de+50,d0cbc5+100 */
    background: rgb(208,203,197); /* Old browsers */
    background: -moz-linear-gradient(left,  rgba(208,203,197,1) 0%, rgba(229,225,222,1) 50%, rgba(208,203,197,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(208,203,197,1) 0%,rgba(229,225,222,1) 50%,rgba(208,203,197,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(208,203,197,1) 0%,rgba(229,225,222,1) 50%,rgba(208,203,197,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d0cbc5', endColorstr='#d0cbc5',GradientType=1 ); /* IE6-9 */
    box-shadow: 0px 1px 0px 0px rgba(250, 250, 250, 1);


}
.banner-section {
    
    div.panel-image {
        background-position: 50% 50%; 
        background-repeat: no-repeat;
        background-size:cover; 
        top: 16% !important; 
        left: 11.5% !important; 
        right: 11.5% !important;  
        bottom: 16% !important;  
        box-shadow: inset 0 0 0 10px rgba(255,255,255,.3);
    }
    .h2 {
        font-family: 'Pinyon Script', cursive;
        font-size: 80px;
        color: #d4c7b2;
        letter-spacing: 0.45px;
        text-transform: capitalize;
        line-height: 65px;
        z-index: 1;
        position: relative;
        text-shadow: 2px 1px 0px rgba(0, 0, 0, 0.19);
        &:before {
            content: none !important;
        }
    }
    .h3 {
        font-family: 'Cinzel', serif;
        font-size: 38px;
        color: #b99860;
        letter-spacing: -1.6px;
        text-transform: uppercase;
        text-shadow: 2px 1px 0px rgba(0, 0, 0, 0.19);
    }
}
.main-slide {

    .owl-stage-outer {
        // background: url('/prositesStyles/StyleLibrary/6309/images/panel-bg.png');
        // background-position: 77.7% 55%;
        // background-repeat: no-repeat;
    }
    .owl-stage {
        .owl-item {
            .panel-image {
                box-shadow: inset 0 0 0 10px rgba(255,255,255,0.3);
            }
            opacity: 0;
            transition-duration: opacity 0.4s !important;
            transition-delay: opacity 0.4s !important;
            transition-timing-function: opacity ease !important;
            &.active {
                opacity: 1;
                transition-duration: opacity 0.4s !important;
                transition-delay: opacity 0s !important;
                transition-timing-function: opacity ease !important;
            }
        }
        .h2 {
            font-family: 'Pinyon Script', cursive;
            font-size: 90px;
            color: #d4c7b2;
            letter-spacing: 0.45px;
            text-transform: capitalize;
            line-height: 65px;
            z-index: 1;
            position: relative;
            text-shadow: 2px 1px 0px rgba(0, 0, 0, 0.19);
            &:before {
                content: none !important;
            }
        }
        .h3 {
            font-family: 'Cinzel', serif;
            font-size: 40px;
            color: #b99860;
            letter-spacing: -1.6px;
            text-transform: uppercase;
            text-shadow: 2px 1px 0px rgba(0, 0, 0, 0.19);
        }
    }
}
.testimonials {
    .h1 {
        &:before {
            color: #4d4238;
        }
    }
    .frame {
        @media screen and (max-width: 991px) {
            & {
                box-shadow: 
                17.5px 17.5px 50px 0 rgba(0,0,0,0.58), 
                0px 340px 187px 0 rgba(244, 201, 151 , 0.11), 
                0 4px 26px 0 rgba(30, 26, 23 , 0.06) 
                !important;
            }
        }
    }
    .container {
        .panel-image {
            box-shadow: inset 0 0 0 10px rgba(255,255,255,0.3);
        }
    }
    .owl-carousel {
        .owl-item {
            padding: 3px !important;
        }
        z-index: 0;
    }

    .mod1 {
        box-shadow: 25px 25px 50px 0px rgba(0, 0, 0, 0.26),
        232px -1px 188px 0px rgba(244, 201, 146, 0.11),
        0px 4px 25px 0px rgba(0, 0, 0, 0.07);
    }
    .ta {
        text-align: left;
    }
    .owl-theme .owl-nav [class*="owl-"] {
        display: none !important;
    }
    h2 {
        position: relative;
        z-index: 0;
    }
    blockquote {
        font-family: 'Crimson-Italic';
        font-size: 18px;
        color: #d4c7b2;
        font-style: italic;
        line-height: 25px;
    }
    footer {
        background: none;
    }
    cite {
        font-family: 'Crimson-Roman';
        color: #d4c7b2;
        text-transform: capitalize;
        font-size: 14px;
    }
}
.main-content {
    .mod1 {
        color: #d4c7b2;

        h1 {
            position: relative;
            z-index: 1;
            margin-bottom: 35px;
        }
    }
}
.main-cont {
    h2 {
        position: relative;
        z-index: 0;
    }
    .form-group {
        box-shadow: 25px 25px 50px 0px rgba(0, 0, 0, 0.26),
        232px -1px 188px 0px rgba(244, 201, 146, 0.11),
        0px 4px 25px 0px rgba(0, 0, 0, 0.07);

        .form-control {
            font-size: 16px;
            background-color: #e5e0dd;
            border: none;
            border-radius: 0;
        }
        textarea {
            padding: .8rem .75rem !important;
            min-height: 135px;
            margin-bottom: 5px;
        }

        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(56, 53, 49, 0.75);
            opacity: 1; /* Firefox */
        }
        
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: rgba(56, 53, 49, 0.75);
        }
        
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: rgba(56, 53, 49, 0.75);
        }
    }
    .btn {
        padding: 13px 60px 10px; 
    }
}
.address1 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d0cbc5+0,e5e1de+50,d0cbc5+100 */
    background: rgb(208,203,197); /* Old browsers */
    background: -moz-linear-gradient(left,  rgba(208,203,197,1) 0%, rgba(229,225,222,1) 50%, rgba(208,203,197,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(208,203,197,1) 0%,rgba(229,225,222,1) 50%,rgba(208,203,197,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(208,203,197,1) 0%,rgba(229,225,222,1) 50%,rgba(208,203,197,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d0cbc5', endColorstr='#d0cbc5',GradientType=1 ); /* IE6-9 */
    font-family: 'Crimson-Roman';
    font-size: 22px;
    text-transform: uppercase;

    .link1 {
        color: #65543f !important;
    }
    .link2 {
        color: #9c8255 !important;
    }
    .link3 {
        color: #9c8255 !important;
    }
    .dv {
        color: #c2beb9;
    }
}
.footer {
    font-family: 'Crimson-Roman';
    background-color: #1c1916;
    color: #b2aba3;

    a {
        color: #b2aba3;
    }
    img {
        position: absolute;
        left: 0;
        bottom: -50px;
    }
    br {
        display: none;
    }
}
.btn-primary {
    background-color: #b99860;
    box-shadow: 0px -1px 0px 0px rgba(239, 216, 176, 1),
    0px 1px 0px 0px rgba(100, 80, 46, 1),
    -1px 0px 0px 0px rgba(108, 91, 63, 1),
    1px 0px 0px 0px rgba(187, 162, 120, 1); 
    border: none;
    padding: 13px 40px 10px 40px;
    border-radius: 2px;
    color: #352f27;
}
.btn-primary:hover {
    background-color: #9e8353;
    color: #352f27;
}

nav ul li a::after {
    box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.3), 1px 0px 0px 0px rgba(255, 255, 255, 0.05); 
    content: " ";
    height: 100%;
    right: 0px;
    position: absolute;
    width: 1px;
}
nav ul li a::before {
    box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.3), 1px 0px 0px 0px rgba(255, 255, 255, 0.05);  
    content: " ";
    height: 100%;
    left: 0px;
    position: absolute;
    width: 1px;
    margin: 0px -1px;
}
.navbar ul.navbar-nav > li .dropdown-menu li a::after {
    background-color: transparent;
    content: none;
    height: 100%;
    right: 0px;
    position: absolute;
    width: 1px;
}
.navbar ul.navbar-nav > li .dropdown-menu li a::before {
    background-color: transparent;
    content: none;
    height: 100%;
    left: 0px;
    position: absolute;
    width: 1px;
    margin: 0px -1px;
}
.social {
    width: 45px;
    height: auto;
    perspective: 1000px;
    z-index: 9999;
    box-shadow: -25px 25px 50px 0px rgba(0, 0, 0, 0.3), 
    0px 4px 25px 0px rgba(0, 0, 0, 0.07);
}

.interior {
    .contact-form {
        label {
            display: none;
        }
    }
    #ctl00_interiorBanner{
        display: block !important;
    }
}
#ctl00_interiorBanner {
    img {
        display: block;
        margin: -30px 0 0 0;
        position: relative;
    }
}
.blog {
    background: url('/prositesStyles/StyleLibrary/6309/images/test-bg.jpg');
    background-repeat: no-repeat;
    background-position: top center; 
    background-size: cover;
    color: #d4c7b2 !important;

    .badge {
        color: #d4c7b2;
    }
}


@media (max-width: 1300px) {
    .main-slide {
        .owl-stage {
            .h2 {
                font-size: 75px;
            }
            .h3 {
                font-size: 35px;
            }
        }
    } 
    .social {
        width: 45px;
        margin-top: 0;

        li {
            a {
                height: auto;
                width: 45px;
            }
        }
        img {
            width: 45px;
            height: auto;
        }
    }
    .banner-section {
        .h2 {
            font-size: 75px;
        }
        .h3 {
            font-size: 35px;
        }

    }
}
@media (max-width: 1199px) {
    .main-slide {
        .owl-stage-outer {
            background-position: 185% 55%;
        }
        .owl-stage {
            .h2 {
                font-size: 60px;
                line-height: 50px;
            }
            .h3 {
                font-size: 27px;
            } background-position: 94% -7px !important;
            }
        }
        .banner-section {
            .h2 {
                font-size: 60px;
                line-height: 50px;
            }
            .h3 {
                font-size: 27px;
            } 

        }
}
@media screen and (max-width: 991px) {
    [id*="OfficeRepeater"] > .address-separator + span + .address-separator {
        display: block;
        font-size: 0;
        height: 0px;
    }

    nav ul li a::after, nav ul li a::before {
        box-shadow: none;
        content: " ";
    }
    .address1 {
        font-size: 16px;
    }
    .testimonials {
        text-align: center;

        .mod {
            margin-right: 0;
        }
        .h2 {
            margin: 0;
            text-align: center;
        }
        .h2::before {
            left: -70px;
        }
       .ta{
           text-align: center;
       }
    } 
    .main-content {
        .container {
            padding: 0 0 65px 0 !important;

            .mod1 {
                text-align: center;

                h1::before {
                    left: -35px;
                }
            }
        }
    }
    .main-slide {
        .owl-stage-outer {
            // background: url('/prositesStyles/StyleLibrary/6309/images/panel-bg2.png');
            // background-repeat: no-repeat;
            // background-position: 5% 15%; 
            .owl-stage {
                .slide-flowers {
                    width: 190px;
                    top: 6px;
                }
            }
        }
    }
    .top-bar {
        .mod1 {
            a {
                margin-right: 20px !important;
            }
        }
        .mod2 {
            a {
                margin-right: 20px !important;
            }
        }
    }
}
@media (max-width: 767px) {
    
    
    [id*="OfficeRepeater"] > .address-separator {
        display: block;
        font-size: 0;
        height: 0px;
    }
    
    .blog {
        padding: 65px 0 65px 35px;
    }
    .team {
        padding: 65px 0 65px 35px;
    }
    #ctl00_interiorBanner img {
        margin: -15px 0 0;
        display: inline-block;
    }
    header {
        .logo {
            .h4 {
                font-size: 25px;
                line-height: 31px;
            }
        } 
    } 
    .footer {
        img {
            display: none;
        }
    } 
    .main-slide {

        .owl-stage-outer {
            background-position: -20px 0px;
            height: auto;
            width: 100%;
            background-size: contain;
            

            .owl-stage {
                h2 {
                    margin: 0 0 0 0;
                    font-size: 45px;
                    line-height: 33px;
                }
                h3 {
                    margin: 0 0 0 0px;
                    font-size: 23px;
                }
                .slide-flowers {
                    top: 7px;
                    width: 160px;
                }
            }
        }
    }
    .top-bar {
        display: none;
    }
    .social {
        width: 35px;
        margin-top: 30px;

        li {
            a {
                height: auto;
                width: 35px;
            }
        }
        img {
            width: 35px;
            height: auto;
        }
    }
}

body.page-type-7,
body.page-type-9 {
    .page-content {
        .list-group-item {
            background: #1c1916;
            border-color: #342e28; 
            & > span > span {
                color: #ebe4dc !important;
            }
        }
    }
}
body.page-type-42 {
    .page-content {
        .categoryLink {
            color: $primary !important;
        }
        [style*="rgba(0,0,0,0.075)"] {
            background: url('../../images/default-blog-image.jpg') no-repeat 50% 50%;
            background-size: cover;
        }
    }
}
body.page-type-43 {
    .financing-page {
        section.bg-light {
            margin-top: 80px !important;
        }
    }
    [id*="careCreditBanner"] {
        p.h1 {
            &:before {
                content: none !important;
            }
        }
    }
    .page-content {
        [style*="-25vw"] {
            margin: 0 !important;
            padding: 0 !important;
            .container {
                padding-left: 50px !important;
                padding-right: 50px !important;
            }
        }
    }
}