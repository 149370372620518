.opacity-100 {
opacity:100 !important;
}

.bg-900 {
color:900px !important;
}

.font-size-14px {
font-size:14px !important;
}

.py-10px {
padding-top:10px;padding-bottom:10px !important;
}

@media screen and (min-width: 1200px) {.mr-xl-30px {
margin-right:30px !important;
}

}
 .mr-20px {
margin-right:20px !important;
}

@media screen and (min-width: 1200px) {.mr-xl-30px {
margin-right:30px !important;
}

}
 .mr-20px {
margin-right:20px !important;
}

.p-0 {
padding:0px !important;
}

.pt-25px {
padding-top:25px !important;
}

.pb-45px {
padding-bottom:45px !important;
}

.pb-10px {
padding-bottom:10px !important;
}

@media screen and (min-width: 768px) {.pb-md-0 {
padding-bottom:0px !important;
}

}
 .mr-0 {
margin-right:0px !important;
}

.m-0 {
margin:0px !important;
}

$theme-colors: map-merge(("65543f":#65543f), $theme-colors);
 .mt-n25px {
margin-top:-25px !important;
}

@media screen and (min-width: 576px) {.px-sm-0 {
padding-left:0px;padding-right:0px !important;
}

}
 .px-10px {
padding-left:10px;padding-right:10px !important;
}

.p-0 {
padding:0px !important;
}

.h-100 {
height:100% !important;
}

.mt-10px {
margin-top:10px !important;
}

@media screen and (min-width: 992px) {.mt-lg-25px {
margin-top:25px !important;
}

}
 .p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.h-100 {
height:100% !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.py-10px {
padding-top:10px;padding-bottom:10px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px;padding-bottom:10px !important;
}

}
 .font-size-14 {
font-size:14px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.p-20px {
padding:20px !important;
}

.mx-2 {
margin-left:2px;margin-right:2px !important;
}

.w-100 {
width:100% !important;
}

.p-25px {
padding:25px !important;
}

.bg-900 {
color:900px !important;
}

.border-800 {
color:800px !important;
}

.ml-10px {
margin-left:10px !important;
}

@media screen and (min-width: 992px) {.py-lg-120px {
padding-top:120px;padding-bottom:120px !important;
}

}
 .py-60px {
padding-top:60px;padding-bottom:60px !important;
}

.py-70px {
padding-top:70px;padding-bottom:70px !important;
}

@media screen and (min-width: 992px) {.py-lg-90px {
padding-top:90px;padding-bottom:90px !important;
}

}
 .h-100 {
height:100% !important;
}

@media screen and (min-width: 992px) {.pt-lg-0 {
padding-top:0px !important;
}

}
 .pt-30px {
padding-top:30px !important;
}

.pb-25px {
padding-bottom:25px !important;
}

.py-100px {
padding-top:100px;padding-bottom:100px !important;
}

.bg-900 {
color:900px !important;
}

.pb-50px {
padding-bottom:50px !important;
}

@media screen and (min-width: 992px) {.pb-lg-0 {
padding-bottom:0px !important;
}

}
 .pb-15px {
padding-bottom:15px !important;
}

.mt-20px {
margin-top:20px !important;
}

.py-25px {
padding-top:25px;padding-bottom:25px !important;
}

.bg-200 {
color:200px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

.bg-100 {
color:100px !important;
}

.py-100px {
padding-top:100px;padding-bottom:100px !important;
}

.my-20px {
margin-top:20px;margin-bottom:20px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

.bg-200 {
color:200px !important;
}

.my-10px {
margin-top:10px;margin-bottom:10px !important;
}

.font-size-14 {
font-size:14px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.font-size-11 {
font-size:11px !important;
}

.pb-10px {
padding-bottom:10px !important;
}

.mr-10px {
margin-right:10px !important;
}

.py-70px {
padding-top:70px;padding-bottom:70px !important;
}

@media screen and (min-width: 992px) {.py-lg-90px {
padding-top:90px;padding-bottom:90px !important;
}

}
 .h-100 {
height:100% !important;
}

.h-100 {
height:100% !important;
}

.h-100 {
height:100% !important;
}

.py-30px {
padding-top:30px;padding-bottom:30px !important;
}

.py-5px {
padding-top:5px;padding-bottom:5px !important;
}

.font-size-20px {
font-size:20px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

$theme-colors: map-merge(("65543f":#65543f), $theme-colors);
 .mx-10px {
margin-left:10px;margin-right:10px !important;
}

.opacity-25 {
opacity:25 !important;
}

.mt-20px {
margin-top:20px !important;
}

.pb-50px {
padding-bottom:50px !important;
}

.py-10px {
padding-top:10px;padding-bottom:10px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.py-50px {
padding-top:50px;padding-bottom:50px !important;
}

.m-0 {
margin:0px !important;
}

.mr-10px {
margin-right:10px !important;
}

.font-size-14 {
font-size:14px !important;
}

.py-50px {
padding-top:50px;padding-bottom:50px !important;
}

.ml-4 {
margin-left:4px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.py-10px {
padding-top:10px;padding-bottom:10px !important;
}

.line-height-30px {
line-height:30px !important;
}

